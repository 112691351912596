// import Head from "next/head";
import { BsFillMoonStarsFill } from 'react-icons/bs'
import { AiFillLinkedin, AiFillGithub, AiOutlineMail } from 'react-icons/ai'
// import img from 'next/image';
import image_h1 from '../src/images/mad_scientist.png'
import doctorappicone from '../src/images/doctorapp.png'
import textbasedgameicone from '../src/images/textbasedadventuregameicon.png'
import tradingicon from '../src/images/tradingicon.png'
import logomacq from '../src/images/logo_macq.png'
import arrow from '../src/images/down_arrow.png'
import './App.css'
import {useState} from "react";
const LinkToCv = 'https://drive.google.com/file/d/15BqkEITDVucltjCWZKfovkgk1uCVkUxT/view?usp=sharing' ;


export default function Home() {
  const [darkMode, setDarkMode] = useState(true);
  return (
    
    <div className={darkMode ? "dark" : ""}>

      <main className="bg-white px-10 dark:bg-gray-900">
        <section className="min-h-screen">
          <nav className="py-10 mb-12 flex justify-between dark:text-white">
            <h1 className="text-xl font-burton">Hamza Portfolio</h1>
            <ul className="flex items-center">
            
              <li className="cursor-pointer text-2xl"><BsFillMoonStarsFill 
                                                        onClick = {() => setDarkMode(!darkMode)}/></li>
              <li>
              <a class="relative bg-gradient-to-r from-blue-700 to-cyan-500 text-white px-4 py-2 rounded-md ml-8" href={LinkToCv} target='_blank' rel="noopener noreferrer">
                Resume
                <span class="absolute -bottom-1 -left-1 flex h-3 w-3">
                  <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-100 opacity-100"></span>
                  <span class="relative inline-flex rounded-full h-3 w-3 bg-teal-500"></span>
                </span>
              </a>
            </li>





            </ul>
          </nav>

          <div className="text-center p-10">
            <h2 className="text-5xl py-2 text-blue-500 dark:text-teal-400 font-medium">Hamza OUAJHAIN</h2>
            <h3 className="text-2xl py-2 dark:text-white">Graduate Software Engineer</h3>
            <p className="text-md py-5 leading-8 text-gray-800 dark:text-gray-200">
              Recent graduate of Vrije Universiteit Brussels &apos;VUB&apos; with a degree in <span className="text-blue-500 dark:text-teal-400">Applied Computer Science</span>, equipped with a strong foundation in computer science and Artificial Intelligence principles, programming, and problem-solving. Highly motivated and eager to apply knowledge and skills to real-world challenges in the field of technology and computing
            </p>
          </div>
          <div className="text-5xl flex justify-center gap-16 py-3 text-gray-700 dark:text-gray-200">
            <a href ="https://github.com/HamzaOuajhain" target='_blank' rel="noopener noreferrer">
              <AiFillGithub />
            </a>
            
            <a href="https://www.linkedin.com/in/ouajhain-hamza/" target="_blank" rel="noopener noreferrer">
              <AiFillLinkedin />
            </a>

            <a href="mailto:Hamzaouajhain@gmail.com" target="_blank" rel="noopener noreferrer">
                <AiOutlineMail size={40} />
              </a>

          </div>
          <div className="relative mx-auto bg-gradient-to-b from-cyan-300 to-blue-500 rounded-full w-80 h-80 mt-20 overflow-hidden">
            <img src={image_h1} layout='fill' objectFit="cover" alt="" />
          </div>



          <section>
            <div className="text-center">
              <h3 className="text-3xl py-4 dark:text-white">Project Portfolio</h3>
              <p className="text-md py-2 leading-8 text-gray-800 dark:text-gray-200">
                With a background in <span className="text-blue-500 dark:text-teal-400">Automation and Industrial Engineering</span>, I have always been captivated by the intersection of technology and innovation. My educational journey led me through the realms of Hardware and Software Development, where I gained invaluable insights into the world of IT.
              </p>
              <p className="text-md py-2 leading-8 text-gray-800 dark:text-gray-200">
                I am driven by my unwavering passion for technology and a strong desire to contribute to <span className="text-blue-500 dark:text-teal-400">the software side </span> of the industry. Having already explored various facets of Hardware & Sofware Engineering, I am eager to leverage my skills and knowledge in software development to create innovative solutions.
              </p>
              <p className="text-md py-2 leading-8 text-gray-800 dark:text-gray-200">
                I have a deep-seated fascination with the endless possibilities that technology offers. In my journey, I&apos;ve had the privilege to work on diverse projects across different domains. I look forward to showcasing some of these projects that reflect my commitment to excellence and my dedication to the world of IT.
              </p>
            </div>
            <div className="lg:flex gap-10">
              <div className="text-center shadow-lg p-10 rounded-xl my-10 dark:bg-white">
                <div className="flex flex-col items-center">
                  <img src={doctorappicone} width={100} height={100} alt="" />
                </div>
                <h3 className="text-lg font-medium pt-8 pb-2">
                  Doctor App &apos;Project For UzBrussels Hospital&apos;
                </h3>
                <p className="mx-auto py-2">
                This is a both<span className="text-blue-500"> Android and iOS application</span>  designed to facilitate communication between doctors and nurses.
                </p>
                <p className="py-2">It is a hybrid application that functions on both Android and iOS devices with a single codebase.</p>
                <h4 className="py-4 text-teal-600">Languages and Technology used</h4>
                <p className="text-gray-800 py-1">Javascript</p>
                <p className="text-gray-800 py-1">Typescript</p>
                <p className="text-gray-800 py-1">React-Native</p>
                <p className="text-gray-800 py-1">Firebase</p>
                <div className="text-5xl flex justify-center gap-1 py-3 text-gray-700 items-center">
                <img src={arrow} alt="Arrow" className="w-6 h-6 animate-bounce-left-to-right" />  
                <a href="https://github.com/HamzaOuajhain/DoctorApp" target='_blank' rel="noopener noreferrer">

                  <AiFillGithub />
                </a>

              </div>


              </div>



              <div className="text-center shadow-lg p-10 rounded-xl my-10 dark:bg-white">
                <div className="flex flex-col items-center">
                  <img src={textbasedgameicone} width={100} height={100} alt="" />
                </div>
                <h3 className="text-lg font-medium pt-8 pb-2 ">
                  Treasure Hunt
                </h3>
                <p className="mx-auto first-letter:py-2 max-w-3xl text-justify">
                This is a <span className="text-blue-500"> Text-based adventure game </span> with a Graphical User Interface (GUI). The objective of the player is to locate the hidden treasure. Players can interact with the game using specific keywords to navigate the map. As you explore the map, you may encounter both monsters or equipments that can either hinder you or aid you in your quest to find the treasure.
                </p>
                <h4 className="py-4 text-teal-600">Language and Technology used</h4>
                <p className="text-gray-800 py-1">Java</p>
                <p className="text-gray-800 py-1">Eclipse</p>
                <div className="text-5xl flex justify-center gap-1 py-3 text-gray-700 items-center">
                  <img src={arrow} alt="Arrow" className="w-6 h-6 animate-bounce-left-to-right" />  
                  <a href ="https://github.com/HamzaOuajhain/Text-Based-Adventure-Game" target='_blank' rel="noopener noreferrer">
                    <AiFillGithub />
                  </a>
                </div>


              </div>

              <div className="text-center shadow-lg p-10 rounded-xl my-10 dark:bg-white ">
                <div className="flex flex-col items-center">
                  <img src={tradingicon} width={100} height={100} alt="" />
                </div>
                <h3 className="text-lg font-medium pt-8 pb-2 ">Trading Helper &apos;Personal Project&apos;</h3>
                <p className="mx-auto py-2 max-w-2xl text-justify">
                This <span className="text-blue-500"> software script </span> assists traders by utilizing tools such as the DOM (Depth of the Market)
                 to enhance their competitive edge and generate profits in the financial markets.
                </p>
                <p className="py-2 max-w-2xl text-justify">Another variant of this script is fully automated 
                and capable of making trading decisions based on market patterns.</p>
                <h4 className="py-4 text-teal-600">Language and Technology used</h4>
                <p className="text-gray-800 py-1">Python</p>
                <p className="text-gray-800 py-1">Visual Studio Code</p>
                <p className="text-gray-800 py-1">Optical charactere recognition &apos;OCR&apos;</p>
              </div>


              
            </div>

              <div className="text-left shadow-lg p-10 rounded-xl my-10 dark:bg-white">
                <div className="flex flex-col items-center">
                  <img src={logomacq} width={100} height={100} alt="" />
                
                <h3 className="text-lg font-medium pt-8 pb-2 ">
                  Traffic Interpolation &apos;Datascience Internship - Belgium&apos;
                </h3>
                <p className="mx-auto py-2 max-w-3xl text-justify">
                  The data science team at Macq is responsible for a traffic interpolation module, meaning estimating traffic counts and speeds on parts of the road network that is not equipped with sensors. Currently, this module is deployed for the Walloon region.
                </p>
                <p className="mx-auto py-2 max-w-3xl">
                  My work consisted of:
                </p>
                <p className="mx-auto py-2 max-w-3xl">- Exploring and preparing the data gathered by the data science team.</p>
                <p className="mx-auto py-2 max-w-3xl">- Exploring the Flemish road network and identifying where Macq had most sensors.</p>
                <p className="mx-auto py-2 max-w-3xl">- Get familiar with the processing environment of the data science team.</p>
                <p className="mx-auto py-2 max-w-3xl">- Get familiar with the interpolation algorithm itself.</p>
                <p className="mx-auto py-2 max-w-3xl">- Launch the interpolation for minimum one week of data.</p>
                <p className="mx-auto py-2 max-w-3xl">- Visualising and assessing the results.</p>
                <p className="mx-auto py-2 max-w-3xl">
                  My task was to <span className="text-blue-500"> test the Datascience Pipeline on the road network of the Flemish region. 
                </span></p>
                <h4 className="py-4 text-teal-600">Language and Technology used</h4>
                <p className="text-gray-800 py-1">Python</p>
                <p className="text-gray-800 py-1">WSL2</p>
                <p className="text-gray-800 py-1">Jupyter Notebook</p>
                <p className="text-gray-800 py-1">Linux</p></div>

{/*<button class="relative bg-gradient-to-r from-blue-700 to-cyan-500 text-white px-4 py-2 rounded-md ml-8 hover:bg-blue-500">
                <span>HOVER HERE</span>
                <span class="absolute inset-0 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity duration-300">
                  <img src= {giif} class="w-full h-full object-cover" alt="Hover GIF" </span>/> </button>*/}
                
                
              

              </div>

          </section>

          <section className="text-center py-10 dark:text-gray-200">
            <h3 className="text-3xl pb-2">Contact Me</h3>
            <div className="flex justify-center gap-8 py-3 text-gray-700 dark:text-gray-200">
              <a href="mailto:Hamzaouajhain@gmail.com" target="_blank" rel="noopener noreferrer">
                <AiOutlineMail size={40} />
              </a>
              <a href="https://www.linkedin.com/in/ouajhain-hamza/" target="_blank" rel="noopener noreferrer">
                <AiFillLinkedin size={40} />
              </a>
              
            </div>
          </section>



        </section>
      </main>
    </div>
  );
}
